import React from 'react';
import Reveal from 'react-awesome-reveal';
import { keyframes } from "@emotion/react";

const fadeInUp = keyframes`
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
`;

const slidermainzero2 = () => (
  <div className="container">
    <div className="row align-items-center">
      <div className="col-md-9 m-auto">
        <div className="spacer-single"></div>
        <div className="spacer-double"></div>
        <Reveal
          className="onStep"
          keyframes={fadeInUp}
          delay={0}
          duration={600}
          triggerOnce
        >
          <h6 className="text-center d-flex justify-content-center">
            <div className="d-flex flex-column">
              <span className="text-uppercase-color">ART IN MOTION</span>
              <div className="spacer-single"></div>
              <img
                src="/img/logoMakelismos.svg"
                className="img-fluid d-block mb-4 img-big"
                alt="#"
              />
            </div>
          </h6>
        </Reveal>
        <div className="spacer-half"></div>
        <Reveal
          className="onStep"
          keyframes={fadeInUp}
          delay={300}
          duration={600}
        >
          <h1 className="text-center headerOne">Platinum private community</h1>
        </Reveal>
        <Reveal
          className="onStep"
          keyframes={fadeInUp}
          delay={600}
          duration={600}
        >
          <p className="lead text-center w-100">
            You have just accessed the private area for platinum collectors.
            Your Mäkelismos NFTs give you access to exclusive content, limited
            art and fashion editions, private sale of NFTs, raffles, encounters
            with René and much more.
          </p>
          <br />
          <p className="lead text-center w-100">
            Come in and enjoy, not everyone can say they have been here.
          </p>
        </Reveal>
        <div className="spacer-10"></div>

        <Reveal
          className="onStep"
          keyframes={fadeInUp}
          delay={800}
          duration={600}
        >
          <div className="d-flex justify-content-center">
            <a
              className="Form__btn"
              href="https://jnhr2mzjzkk.typeform.com/to/RpHtozyh"
              target="_blank"
              rel="noreferrer"
            >
              Form
            </a>
          </div>
          <div className="spacer-single"></div>
          <div className="spacer-half"></div>
        </Reveal>
        <Reveal
          className="onStep"
          keyframes={fadeInUp}
          delay={1000}
          duration={600}
        >
          {/* <div className="wallet-images-group-1">
            <img src="./img/wallet/1.png" alt="" />
            <img src="./img/wallet/2.png" alt="" />
            <img src="./img/wallet/3.png" alt="" />
            <img src="./img/wallet/4.png" alt="" />
            <img src="./img/wallet/5.png" alt="" />
            <img src="./img/wallet/6.png" alt="" />
            <img src="./img/wallet/7.png" alt="" />
            <img src="./img/wallet/8.png" alt="" />
          </div> */}
        </Reveal>
      </div>
    </div>
  </div>
);
export default slidermainzero2;