import React, { useState } from "react";
import { keyframes } from "@emotion/react";
import ItemsCarousel from "react-items-carousel";

const Featurebox0 = () => {
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const chevronWidth = 40;
  const screenWidthMobile = window.screen.width <= 1000;
  const screenWidthTablet = window.screen.width <= 1440;
  const screenWidthDesktop = window.screen.width > 1440;

  return (
    <div className="row ">
      <div className="col-lg-12">
        <div className="text-center">
          <h2>Blog news</h2>
          <span>All the news on our blog</span>
          <div className="small-border"></div>
        </div>
      </div>

      <div style={{ padding: `0 ${chevronWidth}px` }}>
        <ItemsCarousel
          requestToChangeActive={setActiveItemIndex}
          activeItemIndex={activeItemIndex}
          numberOfCards={
            (screenWidthMobile && 1) ||
            (screenWidthTablet && 2) ||
            (screenWidthDesktop && 3)
          }
          gutter={20}
          leftChevron={
            <button className="btnCarousel">
              <img src="./img/collectors/back.svg" alt="back" />
            </button>
          }
          rightChevron={
            <button className="btnCarousel">
              <img src="./img/collectors/next.svg" alt="next" />
            </button>
          }
          outsideChevron
          chevronWidth={chevronWidth}
        >
          <div>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://makelismos.es/blogs/colaboraciones/makelismos-y-porsche-arte-de-primer-nivel-a-todo-gas"
            >
              <img
                alt=""
                src="./img/socials/blog0.png"
                className="lazy"
                style={{ height: "100%", width: "100%", borderRadius: "8px" }}
              />
            </a>
            <p
              style={{
                marginTop: "26px",
                color: "#a2a2a2",
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: "16px",
                lineHeight: "26px",
              }}
            >
              Makelismos and Porsche: Premium art at full throttle.
            </p>
          </div>
          <div>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://makelismos.es/blogs/colaboraciones/los-nft-s-de-makelismos-hacen-sold-out-en-los-angeles-y-nueva-york-de-la-mano-de-the-crypt-gallery-y-dream-hotels"
            >
              <img
                alt=""
                src="./img/socials/blog1.png"
                className="lazy"
                style={{ height: "100%", width: "100%", borderRadius: "8px" }}
              />
            </a>
            <p
              style={{
                marginTop: "26px",
                color: "#a2a2a2",
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: "16px",
                lineHeight: "26px",
              }}
            >
              Makelismos NFTs "sold out" in Los Angeles and New York together
              with The Crypt Gallery and Dream Hotels.
            </p>
          </div>
          <div>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://makelismos.es/blogs/colaboraciones/makelismos-rcd-mallorca-y-la-liga-se-unen-para-homenajear-a-samuel-eto-o-y-dejar-un-legado-artistico-de-primera-division-en-el-estadio"
            >
              <img
                alt=""
                src="./img/socials/blog2.png"
                className="lazy"
                style={{ height: "100%", width: "100%", borderRadius: "8px" }}
              />
            </a>
            <p
              style={{
                marginTop: "26px",
                color: "#a2a2a2",
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: "16px",
                lineHeight: "26px",
              }}
            >
              Makelismos, RCD Mallorca and LaLiga come together to pay tribute
              to Samuel Eto'o and leave a first division artistic legacy in the
              stadium.
            </p>
          </div>
          <div>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://makelismos.es/blogs/colaboraciones/makelismos-y-milbby-un-hermoso-viaje-a-traves-del-arte-para-rendir-tributo-a-la-cultura-espanola"
            >
              <img
                alt=""
                src="./img/socials/blog3.png"
                className="lazy"
                style={{ height: "100%", width: "100%", borderRadius: "8px" }}
              />
            </a>
            <p
              style={{
                marginTop: "26px",
                color: "#a2a2a2",
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: "16px",
                lineHeight: "26px",
              }}
            >
              Makelismos and Milbby: a beautiful journey through art to pay
              tribute to Spanish culture.
            </p>
          </div>
        </ItemsCarousel>
      </div>
    </div>
  );
};
export default Featurebox0;

