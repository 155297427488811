import React from 'react';
import Reveal from 'react-awesome-reveal';
import { keyframes } from "@emotion/react";

const fadeInUp = keyframes`
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
`;

const slidermainzero = ({ scrolIntoView }) => (
  <div className="container">
    <div className="row align-items-center">
      <div className="col-md-9 m-auto">
        <div className="spacer-single"></div>
        <div className="spacer-double"></div>
        <Reveal
          className="onStep"
          keyframes={fadeInUp}
          delay={0}
          duration={600}
          triggerOnce
        >
          <h6 className="text-center d-flex justify-content-center">
            <div className="d-flex flex-column">
              <span className="text-uppercase-color">ART IN MOTION</span>
              <div className="spacer-single"></div>
              <img
                src="/img/logoMakelismos.svg"
                className="img-fluid d-block mb-4 img-big"
                alt="#"
              />
            </div>
          </h6>
        </Reveal>
        <div className="spacer-half"></div>
        <Reveal
          className="onStep"
          keyframes={fadeInUp}
          delay={300}
          duration={600}
        >
          <h1 className="text-center headerOne">NFT Universe</h1>
        </Reveal>
        <Reveal
          className="onStep"
          keyframes={fadeInUp}
          delay={600}
          duration={600}
        >
          <p className="lead text-center w-100">
            The Makelismos NFT division was born to create a technological and
            artistic evolution of the work of René Mäkelä. A new universe full
            of nuances and details to build a new multiverse art concept.
          </p>
          <br />
          <p className="lead text-center w-100">
            A limitless environment for an unlimited creative spirit
          </p>
        </Reveal>
        <div className="spacer-10"></div>

        <Reveal
          className="onStep"
          keyframes={fadeInUp}
          delay={800}
          duration={600}
        >
          <div className="d-flex justify-content-center">
            <span
              onClick={() => {
                window.open("#", "_self");
                scrolIntoView("nft-collection");
              }}
              className="Form__btn"

            >
              Explore
            </span>

          </div>
          <div className="spacer-single"></div>
          <div className="spacer-half"></div>
        </Reveal>
        <Reveal
          className="onStep"
          keyframes={fadeInUp}
          delay={1000}
          duration={600}
        >
          {/* <div className="wallet-images-group-1">
            <img src="./img/wallet/1.png" alt="" />
            <img src="./img/wallet/2.png" alt="" />
            <img src="./img/wallet/3.png" alt="" />
            <img src="./img/wallet/4.png" alt="" />
            <img src="./img/wallet/5.png" alt="" />
            <img src="./img/wallet/6.png" alt="" />
            <img src="./img/wallet/7.png" alt="" />
            <img src="./img/wallet/8.png" alt="" />
          </div> */}
        </Reveal>
      </div>
    </div>
  </div>
);
export default slidermainzero;