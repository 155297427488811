// import MetaMaskOnboarding from "@metamask/onboarding";
import React from "react";
import { useAuth } from "../../helpers/auth";

export default function Wallet({ connect }) {

  const {
    accounts,
    buttonText,
    isDisabled,
    onClick
  } = useAuth();


  return (
    <div className="Wallet">
      <div className="container">
        <div className="Wallet__header row">
          <button onClick={connect} className="Wallet__header-close">
            <img src="./img/wallet/Close.svg" alt="close" />
          </button>
        </div>
        <div className="Wallet__content row">
          <div className="col">
            <h2 className="Wallet__content-title">Connect your wallet</h2>
            <div className="d-flex flex-wrap">
              <h3 className="Wallet__content-account">Account</h3>
              <h3 className="Wallet__content-address">
                {accounts[0] || "disconnected"}
              </h3>
            </div>
            <button
              disabled={isDisabled}
              onClick={onClick}
              className="Wallet__content-btn"
            >
              {buttonText}
              <img src="./img/wallet/1.png" alt="metamask" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
