import React from "react";
import { keyframes } from "@emotion/react";

const fadeInUp = keyframes`
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
`;

const featurebox7 = () => (
  <div className="row">
    <div className="Collaborations feature-box f-boxed-rounded d-flex flex-column align-items-center flex-wrap py-5">
      <h2 className="Collaborations__title">Collaborations</h2>
      <p className="Collaborations__text px-3">
        The Mäkelismos NFT division was born to create a technological and
        artistic evolution of the work of René Mäkelä.
      </p>
      <div className="d-flex flex-row flex-wrap justify-content-center align-items-center">
        <a
          style={{ textDecoration: "none", outline: "none" }}
          href="https://www.palladiumhotelgroup.com/es"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src="./img/partners/palladium.png"
            alt="Palladium hotel group"
            className="img lazy"
            style={{
              height: "50px",
              width: "auto",
              margin: "10px",
            }}
          />
        </a>
        <a
          style={{ textDecoration: "none", outline: "none" }}
          href="https://www.laliga.com/"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src="./img/partners/laliga.svg"
            alt="La liga"
            className="img lazy"
            style={{
              height: "50px",
              width: "auto",
              margin: "10px",
            }}
          />
        </a>
        <a
          style={{ textDecoration: "none", outline: "none" }}
          href="https://www.porsche.com/spain/"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src="./img/partners/porsche.svg"
            alt="Porsche"
            className="img"
            style={{
              height: "50px",
              width: "auto",
              margin: "10px",
            }}
          />
        </a>
        <a
          style={{ textDecoration: "none", outline: "none" }}
          href="https://lacasadelascarcasas.es/"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src="./img/partners/casacarcasas.svg"
            alt="La casa de las carcasas"
            className="img lazy"
            style={{
              height: "50px",
              width: "auto",
              margin: "10px",
            }}
          />
        </a>
        <a
          style={{ textDecoration: "none", outline: "none" }}
          href="https://www.thecryptogallerynft.com/"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src="./img/partners/crypt.svg"
            alt="The crypt Gallery"
            className="img lazy"
            style={{
              height: "50px",
              width: "auto",
              margin: "10px",
            }}
          />
        </a>
        <a
          style={{ textDecoration: "none", outline: "none" }}
          href="https://www.milbby.com/"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src="./img/partners/milbby.svg"
            alt="Milbby"
            className="img lazy"
            style={{
              height: "50px",
              width: "auto",
              margin: "10px",
            }}
          />
        </a>
      </div>
    </div>
  </div>
);
export default featurebox7;
