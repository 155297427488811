import React from "react";
import { keyframes } from "@emotion/react";

const fadeInUp = keyframes`
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
`;

const featurebox9 = ({ type }) => (
  <div className="Form">
    <h2 className="Form__title">Do you want to know more about us?</h2>
    <a
      className="Form__btn"
      href={
        type === "platinum"
          ? "https://jnhr2mzjzkk.typeform.com/to/RpHtozyh"
          : type === "gold"
          ? "https://jnhr2mzjzkk.typeform.com/to/tmmaTIrX"
          : "https://jnhr2mzjzkk.typeform.com/to/c2t10Ven"
      }
      target="_blank"
      rel="noreferrer"
    >
      Form
    </a>
  </div>
);
export default featurebox9;
