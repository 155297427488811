import React from "react";
import { keyframes } from "@emotion/react";

const fadeInUp = keyframes`
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
`;

const featurebox8 = ({ type }) => (
  <div className="Discord row">
    <div className="feature-box d-flex flex-wrap px-5">
      <div className="col-md-6">
        <div className="d-flex flex-column align-items-start">
          <h1 className="mt-3 mb-5">
            {type === "platinum"
              ? "Discord platinum DAO"
              : type === "gold"
              ? "Discord gold DAO"
              : "Discord Free DAO"}
          </h1>
          <div className="d-flex flex-column text-white">
            <span className="Discord__text span mb-3 fs-6">
              • Design of exclusive collections
            </span>
            <span className="Discord__text span mb-3 fs-6">
              • Participate in the creation of the immersive experience
            </span>
            <span className="Discord__text span fs-6">• NFT co-creation</span>
          </div>
          <a
            style={{ textDecoration: "none", outline: "none" }}
            href={
              type === "platinum"
                ? "https://discord.com/channels/917106063025778709/973989814883995688"
                : type === "gold"
                ? "https://discord.com/channels/917106063025778709/973989814883995688"
                : "https://discord.com/invite/vhNSYPZVJQ"
            }
            target="_blank"
            rel="noreferrer"
          >
            <button className="btn-main mt-5">Community</button>
          </a>
        </div>
      </div>
      <img
        //style={{ marginRight: "48px" }}
        src="/img/socials/discord.svg"
        className="Discord__img img-fluid d-block col-md-6"
        alt="#"
      />
    </div>
  </div>
);
export default featurebox8;
