import MetaMaskOnboarding from "@metamask/onboarding";
import React from "react";
import { useAuth } from "../../helpers/auth";

const ONBOARD_TEXT = "Click here to install MetaMask!";
const CONNECT_TEXT = "Connect Wallet";
const CONNECTED_TEXT = "Connected";

export default function Error({ errMsg, close }) {

  return (
    <div className="Wallet">
      <div className="container">
        <div className="Wallet__header row">
          <button onClick={close} className="Wallet__header-close">
            <img src="./img/wallet/Close.svg" alt="close" />
          </button>
        </div>
        <div className="Wallet__content row">
          <div className="col">
            <h2 className="Wallet__content-title">There was an Error:</h2>
            <div className="d-flex flex-wrap">
              <div className="Wallet__content-account">
                <p className="Wallet__content-account--err"> {errMsg}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
