import React from 'react';
import SliderMainZero3 from '../components/SliderMainZero3';
import FeatureBox4 from '../components/FeatureBox4';
import FeatureBox9 from '../components/FeatureBox9';
import FeatureBox0 from '../components/FeatureBox0';
import FeatureBox8 from '../components/FeatureBox8';
import ShopifyThree from '../components/ShoppifyThree';
import ShopifyFour from '../components/ShoppifyFour';

import Footer from '../components/footer';
import { createGlobalStyle } from 'styled-components';
import Wallet from '../components/wallet';
import Error from "../components/error";

const GlobalStyles = createGlobalStyle`
  .h-vh{
    height: 100vh !important;
  }
  section .small-border{
    display: block;
  }
  p.lead, .post-text{
  color: #a2a2a2;
  }
`;

const gold = ({ openModal, connect, close, openModalErr, buttonText }) => (
  <>
    {openModalErr[0] && (
      <section className="container-fluid py-0 ">
        <div className="container d-flex justify-content-center mt-5">
          <Error errMsg={openModalErr[1]} close={close} />
        </div>
      </section>
    )}
    {openModal && (
      <section className="container-fluid py-0 ">
        <div className="container d-flex justify-content-center mt-5">
          <Wallet connect={connect} buttonText={buttonText} />
        </div>
      </section>
    )}
    <div>
      <GlobalStyles />

      <section
        className="container-fluid"
        style={{
          position: "relative",
        }}
      >
        <div
          style={{
            position: "absolute",
            width: "20vw",
            height: "20vw",
            left: "-10vw",
            top: "35%",
            borderRadius: "200px",
            background: "rgba(255, 0, 119, 0.5)",
            filter: "blur(56px)",
          }}
        />
        <div
          style={{
            position: "absolute",
            width: "15vw",
            height: "15vw",
            left: "-10vw",
            top: "55%",
            borderRadius: "200px",
            background: "rgba(185, 185, 185, 0.5)",
            filter: "blur(56px)",
          }}
        />
        <div
          style={{
            position: "absolute",
            width: "18vw",
            height: "18vw",
            right: "-7vw",
            top: "-10%",
            borderRadius: "200px",
            background: "rgba(185, 185, 185, 0.5)",
            filter: "blur(56px)",
          }}
        />
        <div
          style={{
            position: "absolute",
            width: "20vw",
            height: "20vw",
            right: "-6vw",
            top: "0%",
            borderRadius: "200px",
            background: "rgba(255, 0, 119, 0.5)",
            filter: "blur(56px)",
          }}
        />
        <SliderMainZero3 />
      </section>

      <div className="spacer-double"></div>

      <section className="container-fluid py-0">
        <div className="col-lg-12">
          <div className="text-center">
            <h2>The NFTs that give you access</h2>
            <div className="small-border"></div>
          </div>
        </div>

        <div className="container">
          <div className="collections-divider">
            <div>
              <span>Collection - Original René Mäkelä Skull</span>
              <div />
            </div>
            <p>Unblock Gold access</p>
          </div>
          <FeatureBox4 />
        </div>
      </section>

      <div className="spacer-double"></div>

      <section className="container-fluid py-0">
        <div className="container">
          <ShopifyFour />
        </div>
      </section>

      <div className="spacer-double"></div>

      <section className="container-fluid py-0">
        <div className="container">
          <ShopifyThree />
        </div>
      </section>

      <div className="spacer-double"></div>

      <section className="container-fluid py-0">
        <div className="container">
          <FeatureBox8 type={"gold"} />
        </div>
      </section>

      <div className="spacer-double"></div>

      <section className="container-fluid py-0">
        <div className="container feature-box f-boxed-rounded py-5 my-5">
          <FeatureBox9 type={"gold"} />
        </div>
      </section>

      <div className="spacer-double"></div>

      <section className="container-fluid py-0">
        <div className="container">
          <FeatureBox0 />
        </div>
      </section>

      <div className="spacer-double"></div>

      <Footer />
    </div>
  </>
);

export default gold;
