import plat from './platinumABI.json';
import gold from "./goldABI.json";
import platTwo from "./platinumTwoABI.json";


export const collectionGoldData = {
  abi: gold,
  address: "0xcD71f43e4eEA1E2E51C25dAFE3a2a4218D8002d7",
};

export const collectionPlatinumData = {
  abi: plat,
  address: "0x63bA1bB35B72211B6f283502A8CbcF90D898975B",
};

export const collectionPlatinumDataTwo = {
  abi: platTwo,
  address: "0x805bAAAaB9296f2eFC3E9950392f505d2c804D48",
};

// TEST DATA.
export const collectionGoldTestData = {
  type: 'gold',
  abi: gold,
  address: "0x515fc563f2101bb0aa88d813632d04c01b47446d",
};

export const collectionPlatinumTestData = {
  type: 'platinum',
  abi: plat,
  address: "0x1aCAce2414C4a8aEC615A533eDa7CF8A6eBbF57c",
};
