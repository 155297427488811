import React from "react";
import SliderMainZero from "../components/SliderMainZero1";
import FeatureBox from "../components/FeatureBox";
import FeatureBox1 from "../components/FeatureBox1";
import FeatureBox2 from "../components/FeatureBox2";
import FeatureBox3 from "../components/FeatureBox3";
import FeatureBox4 from "../components/FeatureBox4";
import FeatureBox5 from "../components/FeatureBox5";
import FeatureBox6 from "../components/FeatureBox6";
import FeatureBox0 from "../components/FeatureBox0";
import FeatureBox7 from "../components/FeatureBox7";
import FeatureBox8 from "../components/FeatureBox8";
import FeatureBox9 from "../components/FeatureBox9";
import FeatureBox10 from "../components/FeatureBox10";
import FeatureBox11 from "../components/FeatureBox11";

import Footer from "../components/footer";
import { createGlobalStyle } from "styled-components";
import Wallet from "../components/wallet";
import Error from "../components/error";

const GlobalStyles = createGlobalStyle`
  .h-vh{
    height: 100vh !important;
  }
  section .small-border{
    display: block;
  }
  p.lead, .post-text{
  color: #a2a2a2;
  }
`;

const Home = ({
  openModal,
  connect,
  close,
  openModalErr,
  scrolIntoView,
  buttonText,
}) => {
  return (
    <>
      {openModalErr[0] && (
        <section className="container-fluid py-0 ">
          <div className="container d-flex justify-content-center mt-5">
            <Error errMsg={openModalErr[1]} close={close} />
          </div>
        </section>
      )}
      {openModal && (
        <section className="container-fluid py-0 ">
          <div className="container d-flex justify-content-center mt-5">
            <Wallet connect={connect} buttonText={buttonText} />
          </div>
        </section>
      )}
      <div className={openModal ? "home-filter" : ""}>
        <GlobalStyles />

        <section
          className="container-fluid"
          style={{
            background: `black repeat scroll center bottom / cover`,
            position: "relative",
          }}
        >
          <div
            style={{
              position: "absolute",
              width: "20vw",
              height: "20vw",
              left: "-10vw",
              top: "35%",
              borderRadius: "200px",
              background: "rgba(255, 0, 119, 0.5)",
              filter: "blur(56px)",
            }}
          />
          <div
            style={{
              position: "absolute",
              width: "15vw",
              height: "15vw",
              left: "-10vw",
              top: "55%",
              borderRadius: "200px",
              background: "rgba(185, 185, 185, 0.5)",
              filter: "blur(56px)",
            }}
          />
          <div
            style={{
              position: "absolute",
              width: "18vw",
              height: "18vw",
              right: "-7vw",
              top: "-10%",
              borderRadius: "200px",
              background: "rgba(185, 185, 185, 0.5)",
              filter: "blur(56px)",
            }}
          />
          <div
            style={{
              position: "absolute",
              width: "20vw",
              height: "20vw",
              right: "-6vw",
              top: "0%",
              borderRadius: "200px",
              background: "rgba(255, 0, 119, 0.5)",
              filter: "blur(56px)",
            }}
          />
            <SliderMainZero scrolIntoView={scrolIntoView} />
        </section>

        <div className="spacer-double"></div>

        <section id="nft-collection" className="container-fluid py-0">
          <div className="col-lg-12">
            <div className="text-center">
              <h2>NFT Collection</h2>
              <div className="small-border"></div>
            </div>
          </div>
          <div className="container">
            <div className="collections-divider">
              <div>
                <span>Little Basquiat</span>
                <div />
              </div>
              <p>Unblock Platinum access</p>
            </div>
            <FeatureBox5 />
          </div>
          <div className="container">
            <div className="collections-divider">
              <div>
                <span>Next Drop coming soon</span>
                <div />
              </div>
              <p>Unblock Platinum access</p>
            </div>
            <FeatureBox10 />
          </div>
          <div className="container">
            <div className="collections-divider">
              <div>
                <span>Nikola Tesla - The gateway Miami 2022 edition</span>
                <div />
              </div>
              <p>Unblock Gold access</p>
            </div>
            <FeatureBox11 />
          </div>
        </section>

        <div className="spacer-double"></div>

        <section id="nft-collection" className="container-fluid py-0">
          <div className="container">
            <div className="collections-divider">
              <div>
                <span>Collection - Skull Basquiat Tribute</span>
                <div />
              </div>
              <p>Unblock Platinum access</p>
            </div>
            <FeatureBox3 />
          </div>

          <div className="container">
            <div className="collections-divider">
              <div>
                <span>Collection - Original René Mäkelä Skull</span>
                <div />
              </div>
              <p>Unblock Gold access</p>
            </div>
            <FeatureBox4 />
          </div>

          {/* <div className="container">
            <div className="collections-divider">
              <div>
                <span>Little Basquiat</span>
                <div />
              </div>
              <p>Unblock Platinum access</p>
            </div>
            <FeatureBox5 />
          </div> */}
        </section>

        <div className="spacer-double"></div>

        <section className="container-fluid py-0">
          <div className="container">
            <FeatureBox />
          </div>
        </section>

        <div className="spacer-double"></div>

        <section className="container-fluid py-0 ">
          <div className="container">
            <FeatureBox2 connect={connect} />
          </div>
        </section>

        <div className="spacer-double"></div>

        <section className="container-fluid py-0">
          <div className="container">
            <FeatureBox1 />
          </div>
        </section>

        <div className="spacer-double"></div>

        <section className="container-fluid py-0 ">
          <div className="container">
            <FeatureBox7 />
          </div>
        </section>

        <div className="spacer-double"></div>

        <section className="container-fluid py-0 ">
          <div className="container">
            <FeatureBox8 type={"free"} />
          </div>
        </section>

        <div className="spacer-double"></div>

        <section className="container-fluid py-0">
          <div className="container">
            <FeatureBox6 />
          </div>
        </section>

        <div className="spacer-double"></div>

        <section className="container-fluid py-0">
          <div className="container">
            <FeatureBox0 />
          </div>
        </section>

        <div className="spacer-double"></div>

        <section className="container-fluid py-0 ">
          <div className="container feature-box f-boxed-rounded py-5">
            <FeatureBox9 type={"free"} />
          </div>
        </section>

        <div className="spacer-double"></div>

        <Footer />
      </div>
    </>
  );
};

export default Home;
