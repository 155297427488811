import React, { useEffect } from "react";
function onLoad() {
  (function () {
    var scriptURL =
      "https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js";
    if (window.ShopifyBuy) {
      if (window.ShopifyBuy.UI) {
        ShopifyBuyInit();
      } else {
        loadScript();
      }
    } else {
      loadScript();
    }
    function loadScript() {
      var script = document.createElement("script");
      script.async = true;
      script.src = scriptURL;
      (
        document.getElementsByTagName("head")[0] ||
        document.getElementsByTagName("body")[0]
      ).appendChild(script);
      script.onload = ShopifyBuyInit;
    }
    function ShopifyBuyInit() {
      var client = window.ShopifyBuy.buildClient({
        domain: "makelismosbrand.myshopify.com",
        storefrontAccessToken: "192dc327c97067d71d517d9d04ae3352",
      });
      window.ShopifyBuy.UI.onReady(client).then(function (ui) {
        ui.createComponent("product", {
          id: "7827284132082",
          node: document.getElementById("product-component-1668552192641"),
          moneyFormat: "%E2%82%AC%7B%7Bamount_with_comma_separator%7D%7D",
          options: {
            product: {
              styles: {
                product: {
                  "@media (min-width: 601px)": {
                    "max-width": "100%",
                    "margin-left": "0",
                    "margin-bottom": "50px",
                  },
                  "text-align": "left",
                },
                title: {
                  "font-size": "26px",
                  color: "#fff !important",
                },
                button: {
                  color: "#000000",
                  ":hover": {
                    color: "#000000",
                    "background-color": "#e41b79 !important",
                  },
                  "background-color": "rgb(228, 27, 121, 0.7) !important",
                  ":focus": {
                    "background-color": "#e41b79 !important",
                  },
                },
                price: {
                  "font-size": "18px",
                  color: "#fff !important",
                },
                compareAt: {
                  "font-size": "15.299999999999999px",
                },
                unitPrice: {
                  "font-size": "15.299999999999999px",
                },
              },
              layout: "horizontal",
              contents: {
                img: false,
                imgWithCarousel: true,
                button: false,
                buttonWithQuantity: true,
                description: false,
              },
              width: "100%",
              text: {
                button: "Add to cart",
              },
            },
            productSet: {
              styles: {
                products: {
                  "@media (min-width: 601px)": {
                    "margin-left": "-20px",
                  },
                },
              },
            },
            modalProduct: {
              contents: {
                img: false,
                imgWithCarousel: true,
                button: false,
                buttonWithQuantity: true,
              },
              styles: {
                product: {
                  "@media (min-width: 601px)": {
                    "max-width": "100%",
                    "margin-left": "0px",
                    "margin-bottom": "0px",
                  },
                },
                button: {
                  color: "#000000",
                  ":hover": {
                    color: "#000000",
                    "background-color": "#cd186d",
                  },
                  "background-color": "#e41b79",
                  ":focus": {
                    "background-color": "#cd186d",
                  },
                },
                title: {
                  "font-family": "Helvetica Neue, sans-serif",
                  "font-weight": "bold",
                  "font-size": "26px",
                  color: "#4c4c4c",
                },
                price: {
                  "font-family": "Helvetica Neue, sans-serif",
                  "font-weight": "normal",
                  "font-size": "18px",
                  color: "#4c4c4c",
                },
                compareAt: {
                  "font-family": "Helvetica Neue, sans-serif",
                  "font-weight": "normal",
                  "font-size": "15.299999999999999px",
                  color: "#4c4c4c",
                },
                unitPrice: {
                  "font-family": "Helvetica Neue, sans-serif",
                  "font-weight": "normal",
                  "font-size": "15.299999999999999px",
                  color: "#4c4c4c",
                },
              },
              text: {
                button: "Add to cart",
              },
            },
            option: {},
            cart: {
              styles: {
                button: {
                  color: "#000000",
                  ":hover": {
                    color: "#000000",
                    "background-color": "#cd186d",
                  },
                  "background-color": "#e41b79",
                  ":focus": {
                    "background-color": "#cd186d",
                  },
                },
              },
              text: {
                total: "Subtotal",
                button: "Checkout",
              },
            },
            toggle: {
              styles: {
                toggle: {
                  "background-color": "#e41b79",
                  ":hover": {
                    "background-color": "#cd186d",
                  },
                  ":focus": {
                    "background-color": "#cd186d",
                  },
                },
                count: {
                  color: "#000000",
                  ":hover": {
                    color: "#000000",
                  },
                },
                iconPath: {
                  fill: "#000000",
                },
              },
            },
          },
        });
      });
    }
  })();
}

const ShopifyTwo = () => {
  useEffect(() => {
    onLoad();
  }, []);

  return <div id="product-component-1668552192641"></div>;
};
export default ShopifyTwo;
