import React, { useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import ScrollToTopBtn from './components/menu/ScrollToTop';
import Header from './components/menu/header';
import Gold from './components/pages/gold';
import Platinum from './components/pages/platinum';
import Home from './components/pages/home';

import { useAuth } from "./helpers/auth";

import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  :root {
    scroll-behavior: unset;
  }
`;

const ProtectedRouteGold = ({ isOwnerGold, component }) => {
  if (!isOwnerGold) {
    return <Navigate to="/" replace />;
  } else {
    return component;
  }
};

const ProtectedRoutePlat = ({ isOwnerPlat, component }) => {
  if (!isOwnerPlat) {
    return <Navigate to="/" replace />;
  } else {
    return component;
  }
};

const App = () => {
  const {
    isOwnerGold,
    isOwnerPlat,
    buttonText,
    openModalErr,
    setOpenModalErr,
  } = useAuth();

  const [openModal, setOpenModal] = useState(false);

  const connect = () => {
    setOpenModal(!openModal);
  };

  const close = () => {
    setOpenModalErr([false, ""]);
  };

  const scrolIntoView = async (id)=>{
    
    const elem =  document.getElementById(id);
    const rect = elem.getBoundingClientRect();

    window.scrollTo(0, rect.top + window.scrollY);
  };

  return (
    <div
      className={
        (openModal || openModalErr[0]) ? "wraper wraper-modal" : "wraper"
      }
    >
      <GlobalStyles />
      <Header connect={connect} openModal={openModal} buttonText={buttonText} />
      <Routes>
        <Route path="*" element={<Navigate to="/home" replace />} />
        <Route
          path="/home"
          element={
            <Home
              openModal={openModal}
              connect={connect}
              close={close}
              openModalErr={openModalErr}
              scrolIntoView={scrolIntoView}
              buttonText={buttonText}
            />
          }
        />

        <Route
          path="/gold"
          element={
            <ProtectedRouteGold
              isOwnerGold={isOwnerGold}
              component={
                <Gold
                  openModal={openModal}
                  connect={connect}
                  close={close}
                  openModalErr={openModalErr}
                  buttonText={buttonText}
                />
              }
            ></ProtectedRouteGold>
          }
        />

        <Route
          path="/platinum"
          element={
            <ProtectedRoutePlat
              isOwnerPlat={isOwnerPlat}
              component={
                <Platinum
                  openModal={openModal}
                  connect={connect}
                  close={close}
                  openModalErr={openModalErr}
                  buttonText={buttonText}
                />
              }
            ></ProtectedRoutePlat>
          }
        />
      </Routes>
      <ScrollToTopBtn />
    </div>
  );
};
export default App;
