import React from "react";
import Reveal from "react-awesome-reveal";
import { keyframes } from "@emotion/react";

const fadeInUp = keyframes`
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
`;

const featurebox3 = () => (
  <div className="row">
    <div className="col-lg-4 col-md-6 mb-3">
      <div className="feature-box f-boxed-rounded style-3">
        <div className="bloglist item">
          <div className="post-content">
            <div className="post-image">
              <img
                alt=""
                src="./img/collections/goldEdition.png"
                className="lazy"
              />
            </div>
            <div className="post-text">
              <span className="p-tagline">Sold Out</span>
              <h4>
                <span className="collection">
                  Gold Edition<span></span>
                </span>
              </h4>
              <a
                style={{ textDecoration: "none", outline: "none" }}
                href="https://www.niftygateway.com/marketplace/collection/0x63ba1bb35b72211b6f283502a8cbcf90d898975b/1?filters%5bonSale%5d=true&sortBy=lowest"
                target="_blank"
                rel="noreferrer"
              >
                <span className="btn-main">View Collection</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="col-lg-4 col-md-6 mb-3">
      <div className="feature-box f-boxed-rounded style-3">
        <div className="bloglist item">
          <div className="post-content">
            <div className="post-image">
              <img
                alt=""
                src="./img/collections/silverEdition.png"
                className="lazy"
              />
            </div>
            <div className="post-text">
              <span className="p-tagline">SOLD OUT</span>
              <h4>
                <span className="collection">
                  Silver Edition<span></span>
                </span>
              </h4>
              <a
                style={{ textDecoration: "none", outline: "none" }}
                href="https://www.niftygateway.com/marketplace/collection/0x63ba1bb35b72211b6f283502a8cbcf90d898975b/2?filters%5bonSale%5d=true&sortBy=lowest"
                target="_blank"
                rel="noreferrer"
              >
                <span className="btn-main">View Collection</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="col-lg-4 col-md-6 mb-3">
      <div className="feature-box f-boxed-rounded style-3">
        <div className="bloglist item">
          <div className="post-content">
            <div className="post-image">
              <img
                alt=""
                src="./img/collections/whiteEdition.png"
                className="lazy"
              />
            </div>
            <div className="post-text">
              <span className="p-tagline">Sold Out</span>
              <h4>
                <span className="collection">
                  White Edition<span></span>
                </span>
              </h4>
              <a
                style={{ textDecoration: "none", outline: "none" }}
                href="https://www.niftygateway.com/marketplace/collection/0x63ba1bb35b72211b6f283502a8cbcf90d898975b/3?filters%5bonSale%5d=true&sortBy=lowest"
                target="_blank"
                rel="noreferrer"
              >
                <span className="btn-main">View Collection</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
export default featurebox3;
