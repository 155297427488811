import React from "react";
import { keyframes } from "@emotion/react";

const fadeInUp = keyframes`
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
`;

const featurebox11 = () => (
  <div className="row">
    <div className="feature-box row justify-content-evenly align-items-center">
      <video
        muted
        autoPlay
        loop
        controls
        className="img-fluid d-block col-md-6"
      >
        <source src="video/tesla.mp4" type="video/mp4" />
      </video>
      <div className="col-md-4">
        <p className="span-grey mt-3">
          <span className="span-colored">Nikola Tesla: </span>
          on the occasion of The Gateway at Art Basel 2022, the first web3
          metropolis in history, in which MÄKELISMOS and René Mäkelä are
          protagonists with their art, tech and NFT's proposals, we launch this
          collection of an NFT whose 1/1 is owned by Moishe Mana. Culture, art,
          technology, fashion, food and sustainability from digital design that
          reflects the values of change that Nicola Tesla represents.
        </p>
        <a
          style={{ textDecoration: "none", outline: "none" }}
          href="https://opensea.io/assets/ethereum/0x495f947276749ce646f68ac8c248420045cb7b5e/105483083889303443432812687887495333020019887722020607871795785744189359128676"
          target="_blank"
          rel="noreferrer"
        >
          <button className="btn-main mt-3">Buy</button>
        </a>
      </div>
    </div>
  </div>
);

export default featurebox11;
