import React, { useState } from "react";
import { keyframes } from "@emotion/react";
import ItemsCarousel from "react-items-carousel";

const Featurebox0 = () => {
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const chevronWidth = 40;
  const screenWidthMobile = window.screen.width <= 1000;
  const screenWidthTablet = window.screen.width <= 1440;
  const screenWidthDesktop = window.screen.width > 1440;

  return (
    <div className="row ">
      <div className="col-lg-12">
        <div className="text-center">
          <h2>CLOTHING AND ART DIVISION</h2>
          <div className="small-border"></div>
        </div>
      </div>

      <div style={{ padding: `0 ${chevronWidth}px` }}>
        <ItemsCarousel
          requestToChangeActive={setActiveItemIndex}
          activeItemIndex={activeItemIndex}
          numberOfCards={
            (screenWidthMobile && 1) ||
            (screenWidthTablet && 2) ||
            (screenWidthDesktop && 3)
          }
          gutter={20}
          leftChevron={
            <button className="btnCarousel">
              <img src="./img/collectors/back.svg" alt="back" />
            </button>
          }
          rightChevron={
            <button className="btnCarousel">
              <img src="./img/collectors/next.svg" alt="next" />
            </button>
          }
          outsideChevron
          chevronWidth={chevronWidth}
        >
          <div>
            <img
              alt=""
              src="./img/clothing/0.svg"
              className="lazy"
              style={{ height: "100%", width: "100%", borderRadius: "8px" }}
            />
          </div>
          <div>
            <img
              alt=""
              src="./img/clothing/1.svg"
              className="lazy"
              style={{ height: "100%", width: "100%", borderRadius: "8px" }}
            />
          </div>
          <div>
            <img
              alt=""
              src="./img/clothing/2.svg"
              className="lazy"
              style={{ height: "100%", width: "100%", borderRadius: "8px" }}
            />
          </div>
        </ItemsCarousel>
      </div>
      <div className="d-flex flex-column align-items-center justify-content-center text-center">
        <p
          style={{
            marginTop: "26px",
            color: "#a2a2a2",
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "16px",
            lineHeight: "26px",
            padding: "3rem 3rem 0 3rem",
          }}
        >
          MÄKELISMOS Brand is much more than technology and art. It also has a
          fashion and deco line inspired by René's designs. T-shirts,
          sweatshirts, art reproductions in different formats and accessories
          full of life complete an all-terrain brand that is also the perfect
          complement for our collectors to have access to exclusive capsule
          collections through this web3.
          <br />
          <br />
          Discover all the fashion collections that are already sold around the
          world.
        </p>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://makelismos.es"
          style={{ outline: "none", textDecoration: "none" }}
        >
          <button className="btn-main mt-3">Buy</button>
        </a>
      </div>
    </div>
  );
};
export default Featurebox0;

