import Web3 from "web3";
window.web3 = new Web3(window.ethereum);

if (typeof window.ethereum !== "undefined") {
  window.web3 = new Web3(window.ethereum);
} else {
  console.error("Metamask not installed.");
}

const network = [
  {
    chainId: "0x1",
    chainName: "Ethereum Mainnet",
    tokenName: "Ethereum",
    tokenSymbol: "ETH",
    decimals: 18,
    rpcUrls: ["https://mainnet.infura.io/v3/"],
    blockExplorerUrls: ["https://etherscan.io"],
  },
];


/* It's a class that has a constructor that takes in a contractData object, which has an abi and an
address. It then creates a new contract using the abi and address. It also has a status method that
checks if the user is connected to metamask, and a mint method that calls the mint function on the
contract. */
export class Web3Helper {
  constructor(contractData) {
    this.abi = contractData.abi;
    this.address = contractData.address;
    this.contract = new window.web3.eth.Contract(this.abi, this.address);
  }

/**
 * It will add a new network to the user's wallet if it doesn't exist, and then switch to that network
 * @param data
 */
async addNetwork(data) {
  const {
    id,
    chainName,
    tokenName,
    tokenSymbol,
    decimals,
    rpcList,
    explorerList,
  } = data;
  try {
    await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: id }], // Hexadecimal version of 137, prefixed with 0x
    });
  } catch (error) {
    if (error.code === 4902) {
      try {
        await window.ethereum.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainId: id, // Hexadecimal version of 80001, prefixed with 0x
              chainName: chainName,
              nativeCurrency: {
                name: tokenName,
                symbol: tokenSymbol,
                decimals: decimals,
              },
              rpcUrls: rpcList,
              blockExplorerUrls: explorerList,
              iconUrls: [""],
            },
          ],
        });
      } catch (addError) {
        throw new Error(`Only ${chainName} should work`);
      }
    } else throw new Error(`Web3 provider error.`);
  }
}

  /**
   * If the window.ethereum object is undefined, then return false, otherwise return true.
   * @returns A boolean value.
   */
  status() {
    if (window.ethereum === undefined) {
      return false;
    } else {
      return true;
    }
  }
  async getAccount() {
    const accounts = await window.ethereum.request({
      method: "eth_requestAccounts",
    });
    const account = accounts[0];
    return account;
  }

  async balanceOf() {
     await window.ethereum.request({
       method: "wallet_switchEthereumChain",
       params: [{ chainId: "0x1" }],
     });
    const account = await this.getAccount();
    return await this.contract.methods.balanceOf(account).call(
      {
        from: account,
      },
      function (error, result) {
        if (error) {
          console.error(error);
          return error;
        } else {
          return result;
        }
      }
    );
  }

  async tokenOfOwnerByIndex(index) {
    await this.addNetwork(network);
    const account = await this.getAccount();

    try {
      const tokenID = await this.contract.methods
        .tokenOfOwnerByIndex(account, index)
        .call();
      return tokenID;
    } catch (error) {
      return error;
    }
  }
}

