const options = { method: "GET", headers: { accept: "application/json" } };

const getNiftyUsers = async (_contract) => {
  const result = [];
  const artist = "thecryptgallery";
  const limit = "100000";
  const offset = "0";

  return await fetch(
    `https://api.niftygateway.com/v1/creators/${artist}/collectors/?limit=${limit}&offset=${offset}`,
    options
  )
    .then((response) => response.json())
    .then((response) => {
      for (let obj of response.results) {
        if (obj.contractAddress.toUpperCase() === _contract.toUpperCase())
          obj.owner.airdropAddressEth &&
            result.push(obj.owner.airdropAddressEth.toUpperCase());
      }
      return result;
    })
    .catch((err) => {
      console.error(err);
      return err;
    });
};

export default getNiftyUsers;
