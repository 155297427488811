import React, { useState, createContext, useContext, useEffect } from "react";
import { Web3Helper } from "./web3";
import MetaMaskOnboarding from "@metamask/onboarding";
import getNiftyUsers from "./apiNifty";
import {
  collectionGoldData,
  collectionPlatinumData,
  collectionPlatinumDataTwo,
} from "./contracts";

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  // GLOBAL STATE
  const [isOwnerGold, setIsOwnerGold] = useState(false);
  const [isOwnerPlat, setIsOwnerPlat] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [isConnected, setIsConnected] = useState(false);
  const [isDisabled, setDisabled] = React.useState(false);
  const [openModalErr, setOpenModalErr] = useState([false, ""]);
  const ONBOARD_TEXT = "Click here to install MetaMask!";
  const CONNECT_TEXT = "Connect Wallet";
  const CONNECTED_TEXT = "Connected";
  const [buttonText, setButtonText] = React.useState("Connect Wallet");

  // WEB3 EVENT LISTNERS & HANDLERS

  const logout = async () => {
    setIsOwnerGold(false);
    setIsOwnerPlat(false);
  };

  const getAccounts = async () => {
    await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: "0x1" }],
    });

    return await window.ethereum.request({
      method: "eth_requestAccounts",
    });
  };

  const checkConnection = () => {
    window.ethereum
      .request({ method: "eth_accounts" })
      .then((acc) => {
        if (acc.length) {
          setIsConnected(true);
          setButtonText(CONNECTED_TEXT);
          setAccounts(acc);
        }
      })
      .catch(console.error);
  };

  useEffect(() => {
    if (!window.ethereum) {
      return;
    }

    checkConnection();

    const accountWasChanged = (accounts) => {
      if (accounts.length) {
        setIsConnected(true);
        setButtonText(CONNECTED_TEXT);
        setAccounts(accounts);
      } else {
        setIsConnected(false);
        setButtonText(CONNECT_TEXT);
        setAccounts(accounts);
        logout();
      }
    };

    const getAndSetAccount = async () => {
      const changedAccounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      setAccounts(changedAccounts);
      setIsConnected(true);
      setButtonText(CONNECTED_TEXT);
    };

    const clearAccount = () => {
      setAccounts([]);
      setIsConnected(false);
      setButtonText(CONNECT_TEXT);
      logout();
    };

    window.ethereum.on("accountsChanged", accountWasChanged);
    window.ethereum.on("connect", getAndSetAccount);
    window.ethereum.on("disconnect", clearAccount);

    return () => {
      // Return function of a non-async useEffect will clean up on component leaving screen, or from re-reneder to due dependency change
      window.ethereum.off("accountsChanged", accountWasChanged);
      window.ethereum.removeListener("accountsChanged", accountWasChanged);

      window.ethereum.off("connect", getAndSetAccount);
      window.ethereum.removeListener("connect", getAndSetAccount);

      window.ethereum.off("disconnect", clearAccount);
      window.ethereum.removeListener("disconnect", clearAccount);
    };
  }, []);


  // ACCESS CONTROL

  const initialize = async (data) => {
    let web3helper;
    try {
      web3helper = new Web3Helper(data);
    } catch (error) {
      setOpenModalErr([
        true,
        "The Nifty API is currently down, Nifty wallets are custodial wallets, therefore the only way to check if you own an NFT is consulting their API, unfortunatly there's nothing we can do on our side to fix this.",
      ]);
    }
    const balanceOf = await web3helper.balanceOf();
    return !!(balanceOf >= 1);
  };

  const initializeNifty = async (data) => {
    let users;
    try {
      users = await getNiftyUsers(data.address);
    } catch (error) {
      setOpenModalErr([
        true,
        "The Nifty API is currently down, Nifty wallets are custodial wallets, therefore the only way to check if you own an NFT is consulting their API, unfortunatly there's nothing we can do on our side to fix this.",
      ]);
    }
    if (accounts[0]) {
      return await users.includes(accounts[0].toUpperCase());
    } else return false;
  };

  const init = async () => {
    // Setting ownership using Web3.
    setIsOwnerGold(
      (await initialize(collectionGoldData)) ||
        (await initializeNifty(collectionGoldData))
      // (await initialize(collectionGoldDataTwo))
    );

    // Setting 'ownership' using their API.
    setIsOwnerPlat(
      (await initialize(collectionPlatinumData)) ||
        (await initializeNifty(collectionPlatinumData)) ||
        (await initializeNifty(collectionPlatinumDataTwo))
    );
  };

  useEffect(() => {
    if (MetaMaskOnboarding.isMetaMaskInstalled() && isConnected) {
      init();
    }
  }, [accounts]);

  // METAMASK ONBOARDING

  const onboarding = React.useRef();
  React.useEffect(() => {
    setButtonText(ONBOARD_TEXT);
    if (!onboarding.current) {
      onboarding.current = new MetaMaskOnboarding();
    }
  }, []);

  React.useEffect(() => {
    if (MetaMaskOnboarding.isMetaMaskInstalled()) {
      if (accounts.length > 0) {
        setButtonText(CONNECTED_TEXT);
        setDisabled(true);
        setIsConnected(true);
        onboarding.current.stopOnboarding();
      } else {
        setButtonText(CONNECT_TEXT);
        setDisabled(false);
        setIsConnected(false);
      }
    }
  }, [accounts]);

  const onClick = () => {
    if (MetaMaskOnboarding.isMetaMaskInstalled()) {
      getAccounts();
    } else {
      onboarding.current.startOnboarding();
    }
  };

  return (
    <AuthContext.Provider
      value={{
        ONBOARD_TEXT,
        CONNECT_TEXT,
        CONNECTED_TEXT,
        openModalErr,
        setOpenModalErr,
        isOwnerGold,
        isOwnerPlat,
        init,
        logout,
        accounts,
        setAccounts,
        isConnected,
        setIsConnected,
        getAccounts,
        buttonText,
        setButtonText,
        isDisabled,
        setDisabled,
        onClick,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
