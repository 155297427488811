import React, { useEffect, useState } from "react";
import Breakpoint, {
  BreakpointProvider,
  setDefaultBreakpoints,
} from "react-socks";
import { Link, useMatch, useResolvedPath } from "react-router-dom";
import { useAuth } from "../../helpers/auth";

setDefaultBreakpoints([{ xs: 0 }, { l: 1199 }, { xl: 1200 }]);

const NavLink = (props) => {
  let resolved = useResolvedPath(props.to);
  let match = useMatch({ path: resolved.pathname, end: true });

  window.scroll({
    top: 0,
    behavior: "smooth",
  });

  return <Link {...props} className={match ? "active" : "non-active"} />;
};

const Header = function ({ connect, openModal, buttonText }) {
  
  const [showmenu, btn_icon] = useState(false);

  const { isConnected } = useAuth();

  const [currentLocation, setCurrentLocation] = useState("/home");

  useEffect(() => {
    setCurrentLocation(window.location.pathname);
  }, [window.location.pathname]);

  useEffect(() => {
    const headers = document.getElementById("myHeader");
    const totop = document.getElementById("scroll-to-top");
    const sticky = headers.offsetTop;
    const scrollCallBack = window.addEventListener("scroll", () => {
      btn_icon(false);
      if (window.pageYOffset > sticky) {
        headers.classList.add("sticky");
        totop.classList.add("show");
      } else {
        headers.classList.remove("sticky");
        totop.classList.remove("show");
      }
    });
    return () => {
      window.removeEventListener("scroll", scrollCallBack);
    };
  }, []);

  useEffect(() => {
    console.log("wallet connected: ", isConnected);
  }, [isConnected]);

  return (
    <header id="myHeader" className="navbar black">
      <div className="row w-100-nav">
        <div className="logo px-0">
          <div className="navbar-title navbar-item">
            <NavLink to="/">
              <img
                style={{
                  height: "auto",
                  width: "50px",
                  marginTop: "10px",
                }}
                src="/img/logoMakelismos.svg"
                className="img-fluid d-block w-10 h-10"
                alt="#"
              />
              <img
                style={{
                  height: "auto",
                  width: "100px",
                  marginTop: "10px",
                }}
                src="/img/logo-makelismos.png"
                className="img-fluid d-3"
                alt="#"
              />
              <img
                style={{
                  height: "auto",
                  width: "100px",
                  marginTop: "10px",
                }}
                src="/img/logo-makelismos.png"
                className="img-fluid d-none"
                alt="#"
              />
            </NavLink>
          </div>
        </div>

        <BreakpointProvider>
          <Breakpoint l down>
            {showmenu && isConnected && (
              <div className="menu">
                <div className="navbar-item">
                  <div>
                    <div>
                      {currentLocation !== "/home" && (
                        <div className="btn-main btn-main--yellow mx-3 my-1">
                          <NavLink
                            to="/home"
                            onClick={() => btn_icon(!showmenu)}
                          >
                            Homepage
                          </NavLink>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="navbar-item">
                  <div >
                    <div>
                      {currentLocation !== "/gold" && (
                        <div className="btn-main btn-main--yellow mx-3 my-1">
                          <NavLink
                            to="/gold"
                            onClick={() => btn_icon(!showmenu)}
                          >
                            Gold
                          </NavLink>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="navbar-item">
                  <div >
                    <div>
                      {currentLocation !== "/platinum" && (
                        <div className="btn-main btn-main--yellow mx-3 my-1">
                          <NavLink
                            to="/platinum"
                            onClick={() => btn_icon(!showmenu)}
                          >
                            Platinum
                          </NavLink>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Breakpoint>

          <Breakpoint xl>
            {isConnected && (
              <div className="menu">
                <div className="navbar-item">
                  <div >
                    <div>
                      {currentLocation !== "/home" && (
                        <div className="btn-main btn-main--yellow mx-3 my-1">
                          <NavLink
                            to="/home"
                            onClick={() => btn_icon(!showmenu)}
                          >
                            Homepage
                          </NavLink>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="navbar-item">
                  <div >
                    <div>
                      {currentLocation !== "/gold" && (
                        <div className="btn-main btn-main--yellow mx-3 my-1">
                          <NavLink
                            to="/gold"
                            onClick={() => btn_icon(!showmenu)}
                          >
                            Gold
                          </NavLink>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="navbar-item">
                  <div >
                    <div>
                      {currentLocation !== "/platinum" && (
                        <div className="btn-main btn-main--yellow mx-3 my-1">
                          <NavLink
                            to="/platinum"
                            onClick={() => btn_icon(!showmenu)}
                          >
                            Platinum
                          </NavLink>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Breakpoint>
        </BreakpointProvider>

        <div className="mainside">

          <div
            className={
              isConnected
                ? "connect-wal--connected my-1"
                : "connect-wal yellow my-1"
            }
          >
            <button onClick={connect} disabled={openModal}>
              {buttonText}
            </button>
          </div>
        </div>
      </div>

      <button className="nav-icon" onClick={() => btn_icon(!showmenu)}>
        <div className="menu-line white"></div>
        <div className="menu-line1 white"></div>
        <div className="menu-line2 white"></div>
      </button>
    </header>
  );
};

export default Header;
