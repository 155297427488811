import React from "react";
import { keyframes } from "@emotion/react";

const fadeInUp = keyframes`
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
`;

const featurebox5 = () => (
  <div className="row">
    <div className="feature-box row justify-content-evenly align-items-center">
      <video
        muted
        autoPlay
        loop
        controls
        className="img-fluid d-block col-md-6"
      >
        <source src="video/childArtist.mp4" type="video/mp4" />
      </video>

      <div className="col-md-4">
        <p className="span-grey mt-3">
          <span className="span-colored">Every Child is an Artist: </span>
          Little Basquiat Edition is the digital 3D evolution of René Mäkelä’s
          famous and internationally applauded pictorial series.
        </p>
        <span className="span-grey">24 hour ranked auction Edition of 5</span>
        <a
          style={{ textDecoration: "none", outline: "none" }}
          href="https://www.niftygateway.com/collections/everychildisanartist-basquiat"
          target="_blank"
          rel="noreferrer"
        >
          <button className="btn-main mt-3">
            {window.location.pathname === "/home"
              ? "Sold Out"
              : "View Collection"}
          </button>
        </a>
      </div>
    </div>
  </div>
);

export default featurebox5;
