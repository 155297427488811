import React from "react";
import { Link } from "react-router-dom";

const footer = () => (
  <footer className="footer-light">
    <div className="subfooter">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="de-flex">
              <div className="de-flex-col">
                <span onClick={() => window.open("", "_self")}>
                  <img src="./img/logoMakelismos.svg" alt="#" />
                </span>
              </div>
              <div className="de-flex-col">
                <p style={{ color: "#ede75e" }}>
                  Power by &nbsp;
                  <span style={{ textDecoration: "underline" }}>
                    <a
                      style={{ color: "#ede75e" }}
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.changetheblock.com/"
                    >
                      Changetheblock
                    </a>
                  </span>
                </p>
              </div>
              <div className="de-flex-col">
                <div className="social-icons">
                  <span
                    onClick={() =>
                      window.open(
                        "https://www.linkedin.com/company/makelismos/",
                        "_self"
                      )
                    }
                  >
                    <img src="./img/socials/linkedin.png" alt="#" />
                  </span>
                  <span
                    onClick={() =>
                      window.open(
                        "https://twitter.com/makelismos?lang=es",
                        "_self"
                      )
                    }
                  >
                    <img src="./img/socials/twitter.png" alt="#" />
                  </span>
                  <span
                    onClick={() =>
                      window.open(
                        "https://www.instagram.com/makelismos/?hl=es",
                        "_self"
                      )
                    }
                  >
                    <img src="./img/socials/instagram.png" alt="#" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
);
export default footer;
