import React, { useState } from 'react';
import ItemsCarousel from 'react-items-carousel';


const Featurebox1 = () => {
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const chevronWidth = 40;
  const screenWidthMobile = window.screen.width <= 1000;
  const screenWidthTablet = window.screen.width <= 1440;
  const screenWidthDesktop = window.screen.width > 1440;
  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="text-center">
          <h2>Our collectors</h2>
          <div className="small-border"></div>
        </div>
      </div>
      <div style={{ padding: `0 ${chevronWidth}px` }}>
        <ItemsCarousel
          requestToChangeActive={setActiveItemIndex}
          activeItemIndex={activeItemIndex}
          numberOfCards={
            (screenWidthMobile && 1) ||
            (screenWidthTablet && 2) ||
            (screenWidthDesktop && 3)
          }
          gutter={20}
          leftChevron={
            <button className="btnCarousel">
              <img src="./img/collectors/back.svg" alt="back" />
            </button>
          }
          rightChevron={
            <button className="btnCarousel">
              <img src="./img/collectors/next.svg" alt="next" />
            </button>
          }
          outsideChevron
          chevronWidth={chevronWidth}
        >
          <div>
            <img
              style={{ height: "100%", width: "100%", borderRadius: "8px" }}
              src="./img/collectors/1.svg"
              alt="1"
            />
            <p
              style={{
                marginTop: "26px",
                color: "#FFFFFF",
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: "20px",
                lineHeight: "26px",
              }}
            >
              Cara Delevingne
            </p>
          </div>
          <div>
            <img
              style={{ height: "100%", width: "100%", borderRadius: "8px" }}
              src="./img/collectors/2.svg"
              alt="2"
            />
            <p
              style={{
                marginTop: "26px",
                color: "#FFFFFF",
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: "20px",
                lineHeight: "26px",
              }}
            >
              Madona
            </p>
          </div>
          <div>
            <img
              style={{ height: "100%", width: "100%", borderRadius: "8px" }}
              src="./img/collectors/3.svg"
              alt="3"
            />
            <p
              style={{
                marginTop: "26px",
                color: "#FFFFFF",
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: "20px",
                lineHeight: "26px",
              }}
            >
              Pope Francis
            </p>
          </div>
          <div>
            <img
              style={{ height: "100%", width: "100%", borderRadius: "8px" }}
              src="./img/collectors/4.svg"
              alt="4"
            />
            <p
              style={{
                marginTop: "26px",
                color: "#FFFFFF",
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: "20px",
                lineHeight: "26px",
              }}
            >
              Austin Rivers
            </p>
          </div>
          <div>
            <img
              style={{ height: "100%", width: "100%", borderRadius: "8px" }}
              src="./img/collectors/5.svg"
              alt="5"
            />
            <p
              style={{
                marginTop: "26px",
                color: "#FFFFFF",
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: "20px",
                lineHeight: "26px",
              }}
            >
              Cam Newton
            </p>
          </div>
          <div>
            <img
              style={{ height: "100%", width: "100%", borderRadius: "8px" }}
              src="./img/collectors/6.svg"
              alt="6"
            />
            <p
              style={{
                marginTop: "26px",
                color: "#FFFFFF",
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: "20px",
                lineHeight: "26px",
              }}
            >
              Juice Landry
            </p>
          </div>
          <div>
            <img
              style={{ height: "100%", width: "100%", borderRadius: "8px" }}
              src="./img/collectors/7.svg"
              alt="7"
            />
            <p
              style={{
                marginTop: "26px",
                color: "#FFFFFF",
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: "20px",
                lineHeight: "26px",
              }}
            >
              Matt Kemp
            </p>
          </div>
          <div>
            <img
              style={{ height: "100%", width: "100%", borderRadius: "8px" }}
              src="./img/collectors/8.svg"
              alt="8"
            />
            <p
              style={{
                marginTop: "26px",
                color: "#FFFFFF",
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: "20px",
                lineHeight: "26px",
              }}
            >
              Manny Machado
            </p>
          </div>
          <div>
            <img
              style={{ height: "100%", width: "100%", borderRadius: "8px" }}
              src="./img/collectors/9.svg"
              alt="9"
            />
            <p
              style={{
                marginTop: "26px",
                color: "#FFFFFF",
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: "20px",
                lineHeight: "26px",
              }}
            >
              Antoine Griezmann
            </p>
          </div>
        </ItemsCarousel>
      </div>
    </div>
  );};
export default Featurebox1;
