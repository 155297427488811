import React from "react";
import { keyframes } from "@emotion/react";

const fadeInUp = keyframes`
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
`;

const featurebox10 = () => (
  <div className="row">
    <div className="feature-box row justify-content-evenly align-items-center">
      {/* <video
        muted
        autoPlay
        loop
        controls
        className="img-fluid d-block col-md-6"
      >
        <source src="video/childArtist.mp4" type="video/mp4" />
      </video> */}
      <img
        src="./img/collections/imagination.svg"
        alt="The crypt Gallery"
        className="img lazy img-fluid d-block col-md-6"
      />
      <div className="col-md-4">
        <p className="span-grey mt-3">
          <span className="span-colored">Every Child is an Artist: </span>
          Basquiat imagination Edition.
          <br />
          <br />
          Join the wish list. We will notify you as soon as it is available.
          <br />
          <br />
          In partnership with
        </p>

        <img
          src="./img/partners/crypt2.svg"
          alt="The crypt Gallery"
          className="img lazy"
          style={{
            maxWidth: "60vw",
            height: "auto",
            width: "100%",
            margin: "10px",
          }}
        />
        <a
          style={{ textDecoration: "none", outline: "none" }}
          href="https://jnhr2mzjzkk.typeform.com/to/jd4y4Re8"
          target="_blank"
          rel="noreferrer"
        >
          <button className="btn-main mt-3">Form</button>
        </a>
      </div>
    </div>
  </div>
);

export default featurebox10;
