import React from "react";
import { keyframes } from "@emotion/react";

const fadeInUp = keyframes`
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
`;

const featurebox4 = () => (
  <div className="row">
    <div className="col-lg-4 col-md-6 mb-3">
      <div className="feature-box f-boxed-rounded style-3">
        <div className="bloglist item">
          <div className="post-content">
            <div className="post-image">
              <img
                alt=""
                src="./img/collections/originals/green.png"
                className="lazy"
              />
            </div>
            <div className="post-text">
              <h4>
                <span className="collection">
                  ORIGINAL RENÉ MÄKELÄ'S SKULL - GREEN<span></span>
                </span>
              </h4>
              <a
                style={{ textDecoration: "none", outline: "none" }}
                href="   https://www.niftygateway.com/marketplace/collection/0xcd71f43e4eea1e2e51c25dafe3a2a4218d8002d7/2?filters%5bonSale%5d=true&sortBy=lowest"
                target="_blank"
                rel="noreferrer"
              >
                <span className="btn-main">Buy Collection</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="col-lg-4 col-md-6 mb-3">
      <div className="feature-box f-boxed-rounded style-3">
        <div className="bloglist item">
          <div className="post-content">
            <div className="post-image">
              <img
                alt=""
                src="./img/collections/originals/blue.png"
                className="lazy"
              />
            </div>
            <div className="post-text">
              <h4>
                <span className="collection">
                  ORIGINAL RENÉ MÄKELÄ'S SKULL - BLUE<span></span>
                </span>
              </h4>
              <a
                style={{ textDecoration: "none", outline: "none" }}
                href="https://www.niftygateway.com/marketplace/collection/0xcd71f43e4eea1e2e51c25dafe3a2a4218d8002d7/1?filters%5bonSale%5d=true&sortBy=lowest"
                target="_blank"
                rel="noreferrer"
              >
                <span className="btn-main">Buy Collection</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="col-lg-4 col-md-6 mb-3">
      <div className="feature-box f-boxed-rounded style-3">
        <div className="bloglist item">
          <div className="post-content">
            <div className="post-image">
              <img
                alt=""
                src="./img/collections/originals/yellow.png"
                className="lazy"
              />
            </div>
            <div className="post-text">

              <h4>
                <span className="collection">
                  ORIGINAL RENÉ MÄKELÄ'S SKULL - YELLOW<span></span>
                </span>
              </h4>
              <a
                style={{ textDecoration: "none", outline: "none" }}
                href="https://www.niftygateway.com/marketplace/collection/0xcd71f43e4eea1e2e51c25dafe3a2a4218d8002d7/6?filters%5bonSale%5d=true&sortBy=lowest"
                target="_blank"
                rel="noreferrer"
              >
                <span className="btn-main">Buy Collection</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="col-lg-4 col-md-6 mb-3">
      <div className="feature-box f-boxed-rounded style-3">
        <div className="bloglist item">
          <div className="post-content">
            <div className="post-image">
              <img
                alt=""
                src="./img/collections/originals/orange.png"
                className="lazy"
              />
            </div>
            <div className="post-text">
              <h4>
                <span className="collection">
                  ORIGINAL RENÉ MÄKELÄ'S SKULL - ORANGE<span></span>
                </span>
              </h4>
              <a
                style={{ textDecoration: "none", outline: "none" }}
                href="https://www.niftygateway.com/marketplace/collection/0xcd71f43e4eea1e2e51c25dafe3a2a4218d8002d7/4?filters%5bonSale%5d=true&sortBy=lowest"
                target="_blank"
                rel="noreferrer"
              >
                <span className="btn-main">Buy Collection</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="col-lg-4 col-md-6 mb-3">
      <div className="feature-box f-boxed-rounded style-3">
        <div className="bloglist item">
          <div className="post-content">
            <div className="post-image">
              <img
                alt=""
                src="./img/collections/originals/purple.png"
                className="lazy"
              />
            </div>
            <div className="post-text">
              <h4>
                <span className="collection">
                  ORIGINAL RENÉ MÄKELÄ'S SKULL - PURPLE<span></span>
                </span>
              </h4>
              <a
                style={{ textDecoration: "none", outline: "none" }}
                href="https://www.niftygateway.com/marketplace/collection/0xcd71f43e4eea1e2e51c25dafe3a2a4218d8002d7/5?filters%5bonSale%5d=true&sortBy=lowest"
                target="_blank"
                rel="noreferrer"
              >
                <span className="btn-main">Buy Collection</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="col-lg-4 col-md-6 mb-3">
      <div className="feature-box f-boxed-rounded style-3">
        <div className="bloglist item">
          <div className="post-content">
            <div className="post-image">
              <img
                alt=""
                src="./img/collections/originals/red.png"
                className="lazy"
              />
            </div>
            <div className="post-text">
              <h4>
                <span className="collection">
                  ORIGINAL RENÉ MÄKELÄ'S SKULL - RED<span></span>
                </span>
              </h4>
              <a
                style={{ textDecoration: "none", outline: "none" }}
                href="https://www.niftygateway.com/marketplace/collection/0xcd71f43e4eea1e2e51c25dafe3a2a4218d8002d7/3?filters%5bonSale%5d=true&sortBy=lowest"
                target="_blank"
                rel="noreferrer"
              >
                <span className="btn-main">Buy Collection</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
export default featurebox4;
